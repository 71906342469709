export const API = {
  coworkers: {
    create: `/coworker`,
    edit: `/coworker/`,
    delete: `/coworker/`,
    list: `/coworker`,
  },
  zones: {
    create: `/zone`,
    edit: `/zone/`,
    delete: `/zone/`,
    list: `/zone`,
  },
  planners: {
    create: `/planner`,
    edit: `/planner/`,
    delete: `/planner/`,
    list: `/planner`,
    ai: `/planner/ai`,
    oneAI: `/planner/one/ai`,
  },
  menus: {
    create: `/menu`,
    edit: `/menu/`,
    delete: `/menu/`,
    list: `/menu`,
  },
  ingredients: {
    create: `/ingredient`,
    edit: `/ingredient/`,
    delete: `/ingredient/`,
    list: `/ingredient`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
    sendEmail: `/sendEmail`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
