/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* COWORKER */
export const SET_COWORKER_LIST = "SET_COWORKER_LIST";
export const ADD_NEW_COWORKER = "ADD_NEW_COWORKER";
export const UPDATE_COWORKER = "UPDATE_COWORKER";
export const DELETE_COWORKER = "DELETE_COWORKER";
export const OPEN_COWORKER_FORM = "OPEN_COWORKER_FORM";
export const CLOSE_COWORKER_FORM = "CLOSE_COWORKER_FORM";
export const EDIT_SELECTED_COWORKER = "EDIT_SELECTED_COWORKER";
export const COWORKER_FORM_TOOGLE_LOADING = "COWORKER_FORM_TOOGLE_LOADING";
/* <---- COWORKER ----> */

/* ZONE */
export const SET_ZONE_LIST = "SET_ZONE_LIST";
export const ADD_NEW_ZONE = "ADD_NEW_ZONE";
export const UPDATE_ZONE = "UPDATE_ZONE";
export const DELETE_ZONE = "DELETE_ZONE";
export const OPEN_ZONE_FORM = "OPEN_ZONE_FORM";
export const CLOSE_ZONE_FORM = "CLOSE_ZONE_FORM";
export const EDIT_SELECTED_ZONE = "EDIT_SELECTED_ZONE";
export const ZONE_FORM_TOOGLE_LOADING = "ZONE_FORM_TOOGLE_LOADING";
/* <---- ZONE ----> */

/* PLANNER */
export const SET_PLANNER_LIST = "SET_PLANNER_LIST";
export const ADD_NEW_PLANNER = "ADD_NEW_PLANNER";
export const UPDATE_PLANNER = "UPDATE_PLANNER";
export const DELETE_PLANNER = "DELETE_PLANNER";
export const OPEN_PLANNER_FORM = "OPEN_PLANNER_FORM";
export const CLOSE_PLANNER_FORM = "CLOSE_PLANNER_FORM";
export const EDIT_SELECTED_PLANNER = "EDIT_SELECTED_PLANNER";
export const PLANNER_FORM_TOOGLE_LOADING = "PLANNER_FORM_TOOGLE_LOADING";
/* <---- PLANNER ----> */

/* MENU */
export const SET_MENU_LIST = "SET_MENU_LIST";
export const ADD_NEW_MENU = "ADD_NEW_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const DELETE_MENU = "DELETE_MENU";
export const OPEN_MENU_FORM = "OPEN_MENU_FORM";
export const CLOSE_MENU_FORM = "CLOSE_MENU_FORM";
export const EDIT_SELECTED_MENU = "EDIT_SELECTED_MENU";
export const MENU_FORM_TOOGLE_LOADING = "MENU_FORM_TOOGLE_LOADING";
/* <---- MENU ----> */

/* INGREDIENT */
export const SET_INGREDIENT_LIST = "SET_INGREDIENT_LIST";
export const ADD_NEW_INGREDIENT = "ADD_NEW_INGREDIENT";
export const UPDATE_INGREDIENT = "UPDATE_INGREDIENT";
export const DELETE_INGREDIENT = "DELETE_INGREDIENT";
export const OPEN_INGREDIENT_FORM = "OPEN_INGREDIENT_FORM";
export const CLOSE_INGREDIENT_FORM = "CLOSE_INGREDIENT_FORM";
export const EDIT_SELECTED_INGREDIENT = "EDIT_SELECTED_INGREDIENT";
export const INGREDIENT_FORM_TOOGLE_LOADING = "INGREDIENT_FORM_TOOGLE_LOADING";
/* <---- INGREDIENT ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
